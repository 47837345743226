import {createSlice} from "@reduxjs/toolkit";
import {sortByCasinoEstablishedDate, sortByDisplayOrder} from "../../utils/sorters";

declare const window: any;

if (process.env.NODE_ENV !== 'production') {
    window.data = require('../../mock_bonuses.json')
    window.generalTexts = require('../../generalTexts.json');
    // Change Sort Type For Development
    window.componentConfig = {
        stateSettings: {
            sortBy: 'displayOrder',
            // sortBy: 'casinoEstablishedDate'
        },
    }
}

const getInitialData = () => {
    if (window?.componentConfig.stateSettings?.sortBy) {
        switch (window.componentConfig.stateSettings.sortBy) {
            case 'displayOrder':
                window.data = sortByDisplayOrder(window.data);
                break;
            case 'casinoEstablishedDate':
                window.data = sortByCasinoEstablishedDate(window.data);
                break;
        }
    }
    return window.data;
}

const initialState = {
    data: getInitialData(),
    filteredData: [],
    initialLoad: false,
    currentPage: 0,
    maxPage: 0,
    generalTexts: window.generalTexts
}


const casinoListSlice = createSlice({
    name: 'CasinoList',
    initialState,
    reducers: {
        getCasinos: (state, action) => {
            if (!action.payload) {
                state.filteredData = state.data;
                return;
            }
            if (!state.maxPage) {
                state.maxPage = Math.ceil(state.data.length / action.payload.perPage);
            }
            state.currentPage++;
            state.filteredData = state.data.slice(0, state.currentPage * action.payload.perPage);
        },
        setInitialLoad: (state) => {
            state.initialLoad = true;
        }
    }
})

export const {getCasinos, setInitialLoad} = casinoListSlice.actions;
export default casinoListSlice.reducer;
