import React, {useEffect} from 'react';
import './components/scss/Vars.module.scss';
import './components/scss/shared/shared.scss';
import BaseComponent from "./components/BaseComponent/BaseComponent";
import {useDispatch, useSelector} from "react-redux";
import LazyLoad from "vanilla-lazyload";
import {MainStore} from "./types";
import Icons from "./components/Icons";


function App() {

    const {
        stateSettings: {compName, dispatchFunctionName, stateSlice, payload},
    } = useSelector((state: MainStore) => state.componentsConfig);


    const containerClass = process.env.NODE_ENV !== 'production' ? {className: 'container'} : '';


    useEffect(() => {
        async function loadData() {
            const {
                [dispatchFunctionName]: dispatchedAction,
            } = await import(`./state-management/slices/${stateSlice}`);
            dispatch(dispatchedAction(payload));
        }

        loadData().then(() => {
            const tableLoader = document.querySelector('.table-loader');
            if (tableLoader) {
                tableLoader.remove();
            }
        });
        window.addEventListener('load', () => {
            window.addEventListener('shown.bs.collapse', () => {
                const global = (document as any);
                if (!global.lazyloadInstance) {
                    global.lazyloadInstance = new LazyLoad();
                }
                setTimeout(() => {
                    global.lazyloadInstance.update();
                }, 300)
            })
        })
    })
    const dispatch = useDispatch();
    return (
        <div className="App">
            {process.env.NODE_ENV !== 'production' && <style>
                @import url("https://use.typekit.net/xki7eqo.css");
            </style>}
            <Icons/>
            <div {...containerClass}>
                <BaseComponent compName={compName}/>
            </div>
        </div>
    );
}

export default App;
