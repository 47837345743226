import React, {Suspense, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setInitialLoad} from "../../state-management/slices/casinoListSlice";


const BaseComponent = (props: any): JSX.Element => {
    const dispatch = useDispatch();
    const {compName} = props;
    const DynamicComponent = React.lazy(() => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(import(`../${compName}`));

            })
        })
    });
    useEffect(() => {
        setTimeout(() => dispatch(setInitialLoad()), 2000);
    })
    return (compName && <Suspense
        fallback="">
        < DynamicComponent {...props} />
    </Suspense>) as JSX.Element;
}

export default BaseComponent;
