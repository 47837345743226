import {CasinoItemsInterface} from "../types";

export const sortByDisplayOrder = (list: CasinoItemsInterface[]): CasinoItemsInterface[] => {
    return [...list].sort((a, b) => {
        return a.displayOrder - b.displayOrder
    });
}

export const sortByCasinoEstablishedDate = (list: CasinoItemsInterface[]): CasinoItemsInterface[] =>
    list.slice().sort((a, b) =>
        new Date(b.casinoEstablishedDate).getTime() - new Date(a.casinoEstablishedDate).getTime());
