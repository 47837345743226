import {createSlice} from "@reduxjs/toolkit";

let initialState;
if (process.env.NODE_ENV !== 'production') {
    initialState = {
        // // Table View
        //
        // stateSettings: {
        //     compName: 'CasinoItems',
        //     compDataFetch: 'getCasinos',
        //     payload: {perPage: 8},
        //     stateSlice: 'casinoListSlice',
        //     dispatchFunctionName: 'getCasinos',
        // },
        // layoutSettings: {
        //     layout: 'table'
        // },
        // behaviorSettings: {
        //     pagination: true,
        // },

        //Card View

        // stateSettings: {
        //     compName: 'CasinoItems',
        //     compDataFetch: 'getCasinos',
        //     stateSlice: 'casinoListSlice',
        //     dispatchFunctionName: 'getCasinos',
        //     payload: {perPage: 8},
        //     sortBy: 'displayOrder',
        //     // sortBy: 'casinoEstablishedDate'
        // },
        // layoutSettings: {
        //     layout: 'cards'
        // },
        // behaviorSettings: {
        //     pagination: true,
        // },

        // Portal Cards

        stateSettings: {
            compName: 'PortalCasinoItems',
            compDataFetch: 'getCasinos',
            stateSlice: 'casinoListSlice',
            dispatchFunctionName: 'getCasinos',
            sortBy: 'displayOrder'
        },
        layoutSettings: {
            layout: 'cards'
        },
        behaviorSettings: {
            pagination: false,
        },


    }
} else {
    initialState = window.componentConfig;
}


const componentsConfigSLice = createSlice({
    name: 'componentsConfig',
    initialState,
    reducers: {}
})

export default componentsConfigSLice.reducer;
