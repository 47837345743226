import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from './state-management/store';
import {Provider} from 'react-redux';

if (process.env.NODE_ENV !== 'production') {
    require('bootstrap/scss/bootstrap.scss');
    require('bootstrap/dist/js/bootstrap.min.js');
    require('./index.css');
    window.addEventListener('load', () => {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'viewport');
        meta.setAttribute('content', 'width=device-width, initial-scale=1');
        document.head.appendChild(meta);
        const iconMoonCss = document.createElement('link');
        iconMoonCss.href = 'https://d1azc1qln24ryf.cloudfront.net/147386/Cpack/style-cf.css?9d6kzk';
        iconMoonCss.setAttribute('rel', 'stylesheet');
        document.head.appendChild(iconMoonCss);

        //    Make home
        document.body.classList.add('home');

    })
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
