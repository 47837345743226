import {configureStore} from "@reduxjs/toolkit";
import casinoListSlice from './slices/casinoListSlice';
import componentsConfig from "./slices/componentsConfig";

export const store = configureStore({
    reducer: {
        componentsConfig,
        casinoListSlice,
    },
    devTools: process.env.NODE_ENV !== 'production'
});
